<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
            <b-row>
                <b-col>
                    <h4>Timesheet Individual Breakdown</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <label>User</label>
                    <b-input-group>
                        <b-form-input v-model="searchSummary.tech.name" :disabled="true"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm"  text="Button" variant="secondary" @click="openSearchUserModal">Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="4">
                    <label>Date From</label>
                    <b-form-datepicker v-model="searchSummary.dateFrom"></b-form-datepicker>
                </b-col>
                <b-col cols="4">
                    <label>Date To</label>
                    <b-form-datepicker v-model="searchSummary.dateTo"></b-form-datepicker>
                </b-col>
            </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col>
              <b-button variant="primary" squared @click="doExportDocument()">Export</b-button>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" squared @click="doSummarySearch()">Display Summary</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="fetchingData === true">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-center">
              <h6 class="font-weight-bold"> Fetching data. This might take a while</h6>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div v-if="breakdownInformation.hoursBooked !== null && state === 'show'">
      <b-row class="mt-3">
        <b-col cols="10">
          <b-card>
              <stacked-chart v-if="state === 'show'" :height="340" :data="chartData" :labels="chartLabels"
                  class="graph-display"></stacked-chart>
          </b-card>
          
        </b-col>
        <b-col cols="2">
          <b-card class="mb-2">
            <label>Total Time Booked</label>
            <h3>{{breakdownInformation.hoursBooked}}</h3>
          </b-card>
          <b-card class="mb-2">
            <label>Booked on Jobs</label>
            <h3>{{breakdownInformation.hoursOnJobs}}</h3>
          </b-card>
          <b-card class="mb-2">
            <label>Performance Percentage</label>
            <h3>{{breakdownInformation.performancePerc.toFixed(2)}} %</h3>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4>Time Breakdown</h4>
              </b-col>
              <b-col>
                <b-table striped hover :items="tableDataTimeBreakDown.dataSource" :fields="tableDataTimeBreakDown.tableColumns" 
                    :busy="tableDataTimeBreakDown.isLoading" sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4>Call Breakdown Top 10</h4>
              </b-col>
              <b-col>
                <b-table striped hover :items="tableDataCallOutBreakDown.dataSource" :fields="tableDataCallOutBreakDown.tableColumns" 
                    :busy="tableDataCallOutBreakDown.isLoading" sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(date)="row">
                      {{row.item.date | dateFilter}}
                    </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4>Client Breakdown</h4>
              </b-col>
              <b-col>
                <b-table striped hover :items="tableDataClientBreakDown.dataSource" :fields="tableDataClientBreakDown.tableColumns" 
                    :busy="tableDataClientBreakDown.isLoading" sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    
    
    <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
        
        <b-row>
            <b-col cols="4">
                <label>Name</label>
                <b-form-input v-model="user.name"></b-form-input>
            </b-col>
            <b-col cols="4">
                <label>Surname</label>
                <b-form-input v-model="user.surname"></b-form-input>
            </b-col>
            <b-col cols="4">
                <label>Email</label>
                <b-form-input v-model="user.email"></b-form-input>
            </b-col>
        </b-row>
        <hr class="mx-3">
        <b-row>
            <b-col class="text-right">
                <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Cancel</b-button>
                <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col class="12">
                <b-table striped hover 
                        :items="tableDataUsers.dataSource" 
                        :fields="tableDataUsers.tableColumns"
                        :busy="tableDataUsers.isLoading" 
                        @row-clicked="chooseUser"
                        :per-page="tableDataUsers.resultsPerPage"
                        id="userSearch"
                        :current-page="tableDataUsers.currentPage" sort-icon-left>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">
                            <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                            <b-icon-chevron-right></b-icon-chevron-right>
                            </b-button>
                        </b-row>
                    </template>
                </b-table>
            </b-col>
        </b-row>
      
        <b-row align-h="center" >
            <b-pagination
                v-model="tableDataUsers.currentPage"
                :total-rows="userRows"
                :per-page="tableDataUsers.resultsPerPage"
                aria-controls="userSearch"
            ></b-pagination>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
            </b-col>
        </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import StackedChart from "@/components/charts/stackedChart.js";
export default {
  components: {
    StackedChart
  },
  data:() => ({
    state: 'initailizing',
    searchSummary: {
      tech: {name: null},
      dateFrom: null,
      dateTo: null
    },
    chartOptions: {
        hoverBorderWidth: 20
    },
    chartData: [],
    chartLabels: [],
    breakdownInformation: {
      hoursBooked: null,
      hoursOnJobs: null,
      performancePerc: null
    },
    workTypes: [],
    search: {
      supervisor: {name: null},
      description: null,
      dateCreatedFrom: null,
      dateCreatedTo: null
    },
    jsonData: [],
    fetchingData: false,
    user: {
        name: null,
        surname: null,
        email: null,
        roleID: 0
    },
    tableDataUsers: {
        resultsPerPage: 10,
        currentPage: 1,
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
            label: 'Name',
            key: 'name',
            sortable: true,
            tdClass: '',
            },
            {
            label: 'Surname',
            key: 'surname',
            sortable: true,
            tdClass: '',
            },
            {
            label: 'Email',
            key: 'email',
            sortable: true,
            tdClass: '',
            },
            {
            label: '',
            key: 'actions',
            sortable: false,
            tdClass: ''
            }
        ]
    },
    tableDataTimeBreakDown: {
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
              label: 'Description',
              key: 'description',
              sortable: true,
              tdClass: '',
            },
            {
              label: 'Hours',
              key: 'hours',
              sortable: true,
              tdClass: ''
            }
        ]
    },
    tableDataCallOutBreakDown: {
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
              label: 'Description',
              key: 'description',
              sortable: true,
              tdClass: '',
            },
            {
              label: 'Client',
              key: 'client',
              sortable: true,
              tdClass: '',
            },
            {
              label: 'Date',
              key: 'date',
              sortable: true,
              tdClass: ''
            },
            {
              label: 'Hours',
              key: 'hours',
              sortable: true,
              tdClass: ''
            }
        ]
    },
    tableDataClientBreakDown: {
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
              label: 'Client',
              key: 'description',
              sortable: true,
              tdClass: '',
            },
            {
              label: 'Hours',
              key: 'hours',
              sortable: true,
              tdClass: ''
            }
        ]
    },
  }),
  created(){
    this.setBreadcrumb([
        {
            text: 'Reports'
        },
        {
            text: 'Timesheet Individual Breakdown',
            active: true
        }
    ])
    
    this.loadWorkTypes(1)
    .then((res) => {
        this.workTypes = res.data
        this.state = 'show'
    })
    .catch(() => {
        this.state = 'show'
    })
  },
  methods:{
    ...mapActions(['downloadComplianceReport', 'downloadIndividualSummaryReport', 
              'getSummary', 'searchUsersRequest', 'loadWorkTypes']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage']),
    doUserSearch(){
      let request = []    

      this.tableDataUsers.isLoading = true
      this.$store.commit('setUserSearchRequest', request)
      this.searchUsersRequest()
      .then((response) => {
        this.tableDataUsers.dataSource = response.data
        this.tableDataUsers.isLoading = false
        this.state  = 'initialize'
      })
      .catch(() => {
        this.tableDataUsers.isLoading = false
      })
    },
    doSummarySearch(){
      if (this.searchSummary.tech.name === null || this.searchSummary.dateFrom === null || this.searchSummary.dateTo === null) {
        return
      }

      this.state = 'loading'
      let request = {
        technitianId: this.searchSummary.tech.userId,
        fromDateTime: this.searchSummary.dateFrom,
        toDateTime: this.searchSummary.dateTo
      }

      this.$store.commit('setGetSummaryRequest', request)
      this.getSummary()
      .then((res) => {
        this.breakdownInformation = res.data
        this.tableDataTimeBreakDown.dataSource = res.data.otherBreakdown
        this.tableDataCallOutBreakDown.dataSource = res.data.callOutBreakdown
        this.tableDataClientBreakDown.dataSource = res.data.clientBreakdown

        this.tableDataCallOutBreakDown.isLoading = false
        this.tableDataTimeBreakDown.isLoading = false
        this.tableDataClientBreakDown.isLoading = false
      
        let infoLabels = []
        let colours = ['#00AC64', '#00AEEF', '#008694', '#f87979', '#3D5B96', '#1EFFFF', '#00AC64', '#00AEEF', '#008694', '#f87979', '#3D5B96', '#1EFFFF']
        
        let stackedData2 = []
        for (let j = 0; j < this.workTypes.length; j++) {
          const element = this.workTypes[j];
          stackedData2.push({
            id: element.workTypeId,
            label: element.description,
            backgroundColor: colours[j],
            data: []
          })
        }

        for (let i = 0; i < res.data.graphData.length; i++) {
          const element = res.data.graphData[i];
          infoLabels.push(element.date.substr(0,10))
          for (let k = 0; k < stackedData2.length; k++) {
            const stackedElement = stackedData2[k];
            let hours = 0
            let matchingElements = element.workTypeBreakdownForDay.filter(item => item.workTypeId === stackedElement.id)
            for (let c = 0; c < matchingElements.length; c++) {
              hours += matchingElements[c].hours;
            }
            stackedElement.data.push(hours)
          }
        }

        this.chartLabels = infoLabels
        this.chartData = stackedData2
        this.state ='show'
      })
      .catch(() => {
        this.state = 'show'
      })
    },
    openSearchUserModal(){
        this.$bvModal.show('search-user-modal')
        this.doUserSearch()
    },
    closeSearchUserModal(){
        this.$bvModal.hide('search-user-modal')
    },
    clearUsersFilter(){
        this.user = {
            name: null,
            surname: null,
            email: null
        }
    },
    chooseUser(rowItem){
      this.searchSummary.tech = rowItem
      this.closeSearchUserModal()
    },
    doExportDocument(){
      
      if (this.searchSummary.tech.name === null || this.searchSummary.dateFrom === null || this.searchSummary.dateTo === null) {
        return
      }

      this.fetchingData = true
      
      let request = {
          techGroupId: null,
          techId: this.searchSummary.tech.userId,
          dateFrom: this.searchSummary.dateFrom,
          dateTo: this.searchSummary.dateTo,
          ineffiecencyHours: 0,
          lunchHours: 0
      }

      this.$store.commit('setDownloadRequest', request)
      this.downloadEffiecencyReport()
      .then((res) => {
        this.fetchingData = false

        if (res.data !== '') {
          const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
          const downloadLink = document.createElement("a");

          downloadLink.href = linkSource;
          downloadLink.download = res.data.fileName;
          downloadLink.click();
        }
        else{
          this.addWarningMessage('No data available to download')
        }
      })
      .catch(() => {
        this.fetchingData = false
      })
    },
    doSearchUsers(){
        this.tableDataUsers.isLoading = true
  
        let request = []
        
        if (this.user.name != null) {
            request.push({'key': 'name', 'value': this.user.name})
        }
        if (this.user.surname != null) {
            request.push({'key': 'surname', 'value': this.user.surname})
        }
        if (this.user.email != null) {
            request.push({'key': 'email', 'value': this.user.email})
        }
        if (this.user.roleId != null) {
            request.push({'key': 'fkroleId', 'value': this.user.roleId})
        }
        
        this.$store.commit('setUserSearchRequest', request)
        
        this.searchUsersRequest()
        .then((request) => {
            this.tableDataUsers.dataSource = request.data
            
            this.jsonData = this.tableDataUsers.dataSource.map(v => ({
            name: v.name,
            surname: v.surname,
            contactNumber: v.contactNumber,
            email: v.email,
            role: v.role,
            lastLoginDate: v.lastLoginDate === null ? '' : new Date(v.lastLoginDate).toLocaleDateString('en-ZA')
            }))
            
            this.tableDataUsers.isLoading = false
            this.state  = 'initialize'
        })
        .catch( () => {
            this.tableDataUsers.isLoading = false
        })
    },
  },
  computed: {
    userRows() {
        return this.tableDataUsers.dataSource.length
    },
  }
}
</script>
