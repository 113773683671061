// https://codepen.io/VCyan/pen/rvObZR?editors=1010

import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["data", "options", "labels"],
  mounted() {
    this.renderChart({
      labels: this.labels,
      datasets: this.data
    }, {
      responsive: true, 
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          categoryPercentage: 0.5,
          barPercentage: 1
        }],
        yAxes: [{
          stacked: true
        }]
      }
    })
  }
};
